/**
 * Created by mixmedia on 2018/3/1.
 */
/**
 * requireJS 依赖配置
 */
requirejs.config({
    baseUrl: BASE_PATH,
    waitSeconds: 5,
    paths: {
        "text": "common/text/text",
        "lodash": "common/lodash/dist/lodash.min",
        "promise": "common/es6-promise/es6-promise.min",
        "jquery": "common/jquery/jquery.min",
        "sb-admin-2": "common/sb-admin-2/js/sb-admin-2.min",
        "bootstrap": "common/bootstrap/js/bootstrap.min",
        "toastr": "common/toastr-2.1.1/build/toastr.min",
        "app": "common/app",
        "vue-mask": "common/vue-mask/dist/vueTextMask",
        "vue": "common/vue/vue",
        "vuejs": "common/require-vuejs/dist/require-vuejs",
        "vue-resource" : "common/vue-resource/dist/vue-resource.min",
        "vee-validate": "common/vee-validate-2.0.4/dist/vee-validate",
        "vee-validate-cn": "common/vee-validate-2.0.4/dist/locale/zh_TW",
        "jquery.mask": 'common/jquery.mask/dist/jquery.mask.min',
        "jquery.inview": 'common/jquery.inview/jquery.inview.min',
        "jquery.smooth": 'common/jquery.smooth/jquery.smooth-scroll.min',
        "css": "common/require-css/css",
        "hls":"common/dplayer/dist/hls.min",
        "echo":"common/echo-js/dist/echo.min",
        "lazyload":"common/article/jquery.lazyload",
        "common-a":"common/home/common",
        "function":"common/home/function",
        "mythemecms":"common/home/mytheme-cms",
        "mythemesite":"common/home/mytheme-site",
        "mytheme-ui":"common/home/mytheme-ui",
        "jquerycookie": "common/jquery/jquery.cookie",
        "clipboard": "common/clipboard/clipboard.min",
        "flickity": "common/flickity/dist/flickity.pkgd",
        "jquery-bridget":"common/jquery-bridget/jquery-bridget",
        "headroom":"common/headroom.js/dist/headroom",
        "jquery-headroom":"common/headroom.js/dist/jQuery.headroom",
        "swiper":"common/swiper/dist/js/swiper",
        "autocomplete":"common/home/pc/jquery.autocomplete",
        "superslide":"common/home/pc/jquery.SuperSlide.2.1.3",
        "wow":"common/home/pc/wow.min",
        "mi":"common/home/pc/mi",
    },
    map: {
        '*': {
            'css': 'common/require-css/css'
        }
    },
    shim: {
        "bootstrap" : {
            deps: ["jquery"]
        },
        "metisMenu" : {
            deps: ["jquery"]
        },
        "sb-admin-2": {
            deps: ["jquery", "bootstrap", "metisMenu"]
        },
        "toastr" : {
            deps: ["css!common/toastr-2.1.1/build/toastr.min.css"]
        },
        "lazyload" : {
            deps: ["jquery"]
        },
        "autocomplete" : {
            deps: ["jquery"]
        },
        "jquery.mask": {
            deps: ["jquery"]
        },
        "jquery.inview": {
            deps: ["jquery"]
        },
        "jquery.smooth": {
            deps: ["jquery"]
        },
        shim: {
            "vue": {exports: "Vue"},
            "jquery": {exports: "jQuery"},
        }
    }
});